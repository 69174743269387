import { BehaviorSubject, filter } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { Breadcrumb } from '@shared/modules/breadcrumb/breadcrumb.interface';
import { map, startWith } from 'rxjs/operators';
import { isNotUndefined } from '@shared/utils/not-undefined';
import { Store } from '@ngrx/store';
import { selectProduct } from '@shop/store/products.selectors';
import { selectKnowledgeBaseArticle } from '@shop/pages/knowledge-base/pages/article/store/article.selectors';
import { selectCampaignPage } from '@shop/pages/campaign/store/campaign.selectors';

@Injectable()
export class BreadcrumbService {
  // Subject emitting the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  // Observable exposing the breadcrumb hierarchy
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(
    private readonly router: Router,
    private readonly store: Store
  ) {
    this.router.events
      .pipe(
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
        startWith(new NavigationEnd(0, this.router.url, this.router.url)),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        // Construct the breadcrumb hierarchy
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: Breadcrumb[] = [];
        this.addBreadcrumb(root, [], breadcrumbs);

        // Emit the new hierarchy
        this._breadcrumbs$.next(breadcrumbs);
      });
  }

  public addItem(item: Breadcrumb): void {
    this._breadcrumbs$.next([...this._breadcrumbs$.getValue(), item]);
  }

  public removeItem(item: Breadcrumb): void {
    this._breadcrumbs$.next(this._breadcrumbs$.getValue().filter((breadcrumb) => breadcrumb !== item));
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
    if (route && route.firstChild) {
      // Construct the route URL
      const routeUrl = [...parentUrl, ...route.url.map((url) => url.path)];
      const isObservableBreadcrumb = route.data.productBreadcrumb || route.data.articleBreadcrumb || route.data.campaignBreadcrumb;

      // Add an element for the current route part
      if (route.data.breadcrumb) {
        const breadcrumb: Breadcrumb = isObservableBreadcrumb
          ? {
              label$: this.getObservableLabel(route.data),
              route: ['/', ...routeUrl]
            }
          : {
              label: this.getLabel(route.data),
              route: ['/', ...routeUrl]
            };
        // Push breadcrumb only if doesn't exist
        if (!breadcrumbs.some((b) => b.label === breadcrumb.label)) {
          breadcrumbs.push(breadcrumb);
        }
      }

      // Add another element for the next route part
      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  private getObservableLabel(data: Data) {
    if (data.articleBreadcrumb) {
      return this.store.select(selectKnowledgeBaseArticle).pipe(
        filter(isNotUndefined),
        // eslint-disable-next-line @ngrx/avoid-mapping-selectors
        map((article) => {
          return !!article.breadcrumb ? article.breadcrumb : article.title;
        })
      );
    }

    if (data.campaignBreadcrumb) {
      return this.store.select(selectCampaignPage).pipe(
        filter(isNotUndefined),
        // eslint-disable-next-line @ngrx/avoid-mapping-selectors
        map((campaign) => {
          return !!campaign.breadcrumb ? campaign.breadcrumb : campaign.title;
        })
      );
    }

    return this.store.select(selectProduct).pipe(
      filter(isNotUndefined),
      // eslint-disable-next-line @ngrx/avoid-mapping-selectors
      map((productItem) => {
        return productItem.title;
      })
    );
  }

  private getLabel(data: Data) {
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    return typeof data.breadcrumb === 'function' ? data.breadcrumb(data) : data.breadcrumb;
  }
}
