import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ToastMessageService } from '@shared/modules/toast-message/toast-message.service';

@Injectable({ providedIn: 'root' })
export class RequestIdInterceptor implements HttpInterceptor {
  private readonly toastService = inject(ToastMessageService);
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.url.includes('direct-diagnostics')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((response) => {
        if (response.error?.requestId) {
          console.error(`Request failed with id: ${response.error.requestId}`);
        }
        return throwError(() => response);
      })
    );
  }
}
